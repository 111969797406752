export default {
  gamePlay: {
    playTitle: "Translate and respond!",
    playInstructions: "<p>Where was the last {{count}} blob of each color?<br /><br /> Check the color order at the top of your screen.<br />Only click the colors shown.</p>",
    playButton: 'Start translating!'
  },
  rounds: {
    end: "End of Round {{round}}",
    playNext: "Play next round!",
    all: "Excellent! You got {{correct}} trials correct!",
    some: "Great job! You got {{correct}} trials correct in this round.",
    none: "Good try. Let's keep practicing!"
  },
    levelUp: {
        title: "Amazing job, level up!!!",
        bodyRender: (level) => `Moving to level ${level}, and you got some tickets!`
    },
  gameComplete: {
    playTitle: "Game Completed!",
    playInstructions: "Great work, keep practicing!",
    playButton: 'Return to games'
  },
    instructions: [
        {
            'title': 'First Contact',
            'body': 'The alien language uses colored spots instead of letters.<br><br>' +
                'The aliens aren\'t used to our gravity, so it might take them a few tries to get each color in the right spot.',
            'buttonText': 'Engage',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Keep Track',
            'body': 'Keep track of where you saw the LAST BLOB of EACH COLOR.<br><br>\n' +
                'Check the top of your screen for which colors to track.<br><br>\n' +
                'Ignore all the other colored blobs.',
            'buttonText': 'Engage',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Level Up',
            'body': 'As you level up, you\'ll track more spots for each color.',
            'buttonText': 'Engage',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Color Order',
            'body': 'Check the Color Order at the top of your screen.<br><br>'+
            'Unlock the Order Bonus by clicking the spots in the COLOR ORDER.',
            'buttonText': 'Engage',
            'buttonAction': 'goToNext'
        },
        {
            'title': 'Ready to start?',
            'body': 'Press yes to start, no to see the instructions again.',
            'buttonText': 'Yes',
            'buttonText2': 'No',
            'buttonAction': 'goToPlay',
            'buttonAction2': 'goToBeginning'
        }
    ]
}
