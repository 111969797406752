//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import GlobalMixin from 'src/shared/GlobalMixins.js';
import GamePlayMixin from 'src/shared/GamePlayMixin.js';
import InitializeGameMixin from "src/shared/InitializeGameMixin";
import Animation from '../../../../../shared/Animation.js';
import gamePlayCopy from '../data/copy.js';

export default {
  name: 'Play',
  mixins: [GlobalMixin, GamePlayMixin, Animation, InitializeGameMixin],
  components: {
    Timer: () => import(/* webpackChunkName: "timer" */ '../../../Common/Timer')
  },
  data() {
    return {
      activeButtons: [],
      buttonIndexes: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23],
      buttonReaction: null,
      buttonReactionClass: null,
      buttons: [],
      container: null,
      gamePlayCopy: gamePlayCopy,
      correctThisRound: 0,
      endRoundText: '',
      hintMode: true,
      hintedButton: false,
      incorrectAnswers: false,
      nextTrial: false,
      playDisabled: false,
      playStart: null,
      showAlienShip: true,
      showOrderBonus: false,
      totalAnswers: 0,
      totalAnswersPerTrial: 1,
      totalDisplayColors: 0,
      totalQueryColors: 0,
      trialStart: null,
      wrongOrder: false,
      showButtons: false
    };
  },
  methods: {
    /**
     * Initialize the game play
     * @returns {void}
     */
    async initializeGamePlay() {
      // don't let them play until we get past the instruction modal
      this.setGameExited(false);
      this.setTimer('false');
      this.setShowTimer(false);
      this.setScore(0);
      this.container = this.$refs.gameplay;
      this.alienShip = this.$refs.alienShip;
      this.playDisabled = true;
      this.hintedButton = false;
      this.incorrectAnswers = false;
      this.setTextPlayHints('');
      // reveal selected buttons for this session
      console.log('initializing gameplay');

      // loop for each round
      // get the user level, setup grid for first trial/round
      await this.getSettings(); // this will actually get the user, grid data
      await this.setupButtons();
      await this.animateInAliensAndBoard();

      await this.showGameplayHints();
      await this.sleep(100); // wait
      this.playStart = new Date().getTime(); // set the play start time
    },
    /**
     * Get the game settings
     * @returns {Promise<void>}
     */
    async getSettings() {
      let gameData = await this.startGamePlay({
        gameId: this.gameId, playId: this.play_id
      });
      this.setGameLevelData(gameData);

      this.setLevel(this.gameLevelData.start_level);
      this.setRounds(5);
      this.setTrials(2);
      this.hintedButton = false;
      this.totalQueryColors = this.gameLevelData.game_settings.query_colors;
      this.totalDisplayColors = this.gameLevelData.game_settings.display_colors;
    },
    /**
     * Animate in the aliens and the board
     * @returns {Promise<void>}
     */
    async animateInAliensAndBoard() {
      this.showAlienShip = true;
      let alienShipHeight = this.$refs.alienShip.clientHeight;

      await this.animation.to([this.container], 3, {
        y: -Math.abs(alienShipHeight) +
            (window.innerHeight / 8),
        ease: this.parseEase('easeOutExpo')
      });

      await this.sleep(100); // wait
    },
    /**
     * Initialize the default button grid
     * @returns {Promise<unknown>}
     */
    async initializeEmptyButtons() {
      // setup blank grid
      this.buttons = this.buttonIndexes.map(() => ({
          clicked: false,
          isActive: false,
          activeColor: false,
          correct: false,
          reaction: null
        }));
    },
    /**
     * Setup the correct buttons for the grid
     * @returns {Promise<void>}
     */
      async setupButtons() {
      this.activeButtons = [];

      this.clearAllReactions();
      // shuffle colors
      this.shuffleArray(this.colorValues);
      await this.initializeEmptyButtons();

      // get total colors from level data
      let totalColors = this.gameLevelData.game_settings.display_colors;
      // get buttons per color min/max
      let minDotsPerColor = this.gameLevelData.game_settings.min_dots_per_color;
      let maxDotsPerColor = this.gameLevelData.game_settings.max_dots_per_color;

      // get colors based on amount needed, min/max per color
      let selectedColors = [];

      let numberPerColor = this.getRandomIntegerInRange(minDotsPerColor, maxDotsPerColor);

      for (let i = 0; i < totalColors; i++) {
        selectedColors.push(this.colorValues[i]);
        console.log('selected colors', selectedColors.length);
      }

      selectedColors = this.shuffleArray(selectedColors);

      this.colorValues.filter(item => {
        return !selectedColors.some(selectedItem => item.name === selectedItem.name);
      });

      // get hints but then only keep the amount we need based on query colors
      let hints = selectedColors.map(a => a.name).slice(0, this.totalQueryColors);
      // setup the grid and randomize the numbers
      let gridIndices = [...this.buttonIndexes];
      gridIndices = this.shuffleArray(gridIndices);

      selectedColors.forEach(color => {
        Array.from({length: numberPerColor}).forEach((_, i) => {
          const index = gridIndices.shift();
          const button = this.buttons[index];

          button.activeColor = color.name; 
          button.isActive = true;
          button.index = index;
          this.activeButtons.push(button);
        });
      });

      this.activeButtons = this.shuffleArray(this.activeButtons);

      this.totalAnswersPerTrial = hints.length; 
      console.log('total answers per trial', this.totalAnswersPerTrial);
      this.setHints(hints);

      if (this.activeButtons.length === 0) {
        throw new Error('No active buttons were set for this round.');
      }
    },
    /**
     * Start the main game play mode
     * @returns {Promise<void>}
     */
    async startGamePlayMode() {
      this.playDisabled = false;
      this.resetModalMessage();
      this.setMusic(this.assets.audio.gamePlay, true);

      this.trialStart = new Date().getTime();
    },
    /**
     * Show the code button sequence to the player
     * @returns {Promise<unknown>}
     */
    async showButtonSequence() {
      let i = 0;
      this.showButtons = true;
      this.hintedButton = false;

      for (let button of this.activeButtons) {
        if (this.gameExited) {
          this.unloadMusic();
          return; // early return if the game is exited
        }

        this.hintedButton = button.index;
        await this.startTimer((this.getStimulusDisplayInterval() / 100));
        await this.sleep(this.getStimulusDisplayInterval());
        await this.stopAndHideTimer();

        this.hintedButton = false; // Reset the hintedButton immediately
        await this.$nextTick(); // Ensure Vue has updated the DOM

        // Adjust the sleep time for the last iteration
        let sleepTime = (i === this.activeButtons.length - 1) ? 200 : this.getRandomLevelISI();
        await this.sleep(sleepTime);

        i++;
      }

      this.hintedButton = false;
    },
    /**
     * Setup the code button hints for the player
     * @param count
     * @returns {Promise<void>}
     */
    async showGameplayHints(count = 1) {
      console.log('start showing button hints');
      this.setTimerStopAfter(this.getStimulusDisplayInterval());
      // show hints dependent on Level
      this.hintMode = true;
      this.buttonDisabled = false;
      this.setMusic(this.assets.audio.gamePlay, true);

      await this.showButtonSequence();
      console.log('end button hints');
      console.log(this.gameExited);
      if (this.gameExited) {
        this.stopMusic();
        return; // make sure if the user exits at this stage, we don't keep going
      }

      this.setModalMessage({
        title: gamePlayCopy.gamePlay.playTitle,
        body: this.formatText(gamePlayCopy.gamePlay.playInstructions, {count: count}),
        actionButton: {action: this.startGamePlayMode, text: gamePlayCopy.gamePlay.playButton}
      });

      this.hintMode = false;

    },
    /**
     * Get the button display state for a particular button
     * @param the_button
     * @param index
     * @returns {string|*}
     */
    getButtonState(the_button, index) {
      let returnImage = this.assets.images.buttonOff;

      switch (true) {
        case the_button.reaction === 'correct':
          returnImage = this.assets.images.correct;
          break;
        case the_button.reaction === 'correct_order':
          returnImage = this.assets.images.correct_order;
          break;
        case the_button.reaction === 'incorrect':
          returnImage = this.assets.images.incorrect;
          break;
        case !the_button.isActive && the_button.clicked || !the_button.activeColor || !this.showButtons:
          returnImage = this.assets.images.buttonOff;
          break;
        case this.hintedButton === index:
          returnImage = this.assets.images['button' + this.capitalize(the_button.activeColor)];
          break;
      }

      return returnImage;
    },
    /**
     * Set the reaction for a button
     * @param button
     * @param reaction
     * @returns {void}
     */
    setButtonReaction(button, reaction) {
      this.buttons[button].reaction = reaction;
    },
    /**
     * React to an incorrect button press
     * @param button
     * @returns {Promise<void>}
     */
    async setWrongAnswer(button) {
      console.log('wrong answer');
      this.incorrectAnswers = true;
      this.decreaseScore(this.scoreDownIncorrect);
      this.setEffect(this.assets.audio.effectIncorrect);

      await this.setButtonReaction(button, 'incorrect');
    },
    /**
     * Handle hint mode button presses
     * @param button
     * @returns {Promise<void>}
     */
    async handleHintMode(button) {
      // if this is hint mode, they should only be able to click the hints. it should give them points
      // otherwise there should be no reaction whatsoever
      if (button === this.hintedButton) {
        await this.stopAndHideTimer();
        console.log('clicked during hint mode');
        this.setEffect(this.assets.audio.effectPresentation);
        this.increaseScore(this.scoreUpHint);
        this.hintedButton = false;
      }
    },
    async evaluateResponse(button, currentButton, activeHint) {
      const isInOrder = currentButton.activeColor === activeHint;
      console.log('activeButtons', this.activeButtons);

      // answer is correct if it's both in the activeButtons array and the current button color is in hints array
      if (this.expectedAnswers.some((item) => item.index === button) && this.hints.includes(currentButton.activeColor)) {
        console.log('this button matches the color of the active hint');

        const timerValue = isNaN(this.timer) ? 0 : this.timer;
        const timerBonus = Math.max(Math.ceil((100 - Math.abs(timerValue)) / 10) * 25, 0);
        this.increaseScore(this.scoreUpTrial + timerBonus);

        // if they already answered incorrectly for this trial, then we don't want to mark anything as correct for this trial
        if (!this.incorrectAnswers) {
          console.log('adding to correct answers');
          this.addToCorrectAnswers(button);
        }
        this.correctThisRound = this.correctThisRound + 1; // make sure this makes it through the round

        this.setEffect(this.assets.audio.effectCorrect);
        this.stopMusic();

        //if this is in the right order, then we should give them an order bonus
        if (isInOrder) {
          console.log('in order, show order bonus');
          this.increaseScore(100);
          await this.transitionOrderBonus();
          console.log('in order, show order bonus');
        }
        await this.setButtonReaction(button, 'correct');
      } else {
        this.incorrectAnswers = true;
        await this.setWrongAnswer(button);
      }
    },
    /**
     * Handle game play mode button presses
     * @param currentButton
     * @param button
     * @param activeHint
     * @returns {Promise<void>}
     */
    async handleGamePlayMode(currentButton, button, activeHint) {
      if (!this.playDisabled) {
        if (!currentButton.isActive) {
          await this.setWrongAnswer(button);
        } else {
          console.log('active button clicked');
          await this.evaluateResponse(button, currentButton, activeHint);
        }

        this.totalAnswers++;
        console.log('total answers ' + this.totalAnswers);
      }

      // get the count of activeButtons that have an activeColor that is in the hints array
      if (this.totalAnswers === this.expectedAnswerCount) {
        await this.stopAndHideTimer();

        if (!this.incorrectAnswers) {
          this.increaseTrialsCorrect();
          this.trialWasCorrect = true;
          this.setTrialStatus('correct');
        } else {
          this.trialWasCorrect = false;
          this.setTrialStatus('incorrect');
        }

        await this.sleep(1200); // don't want to animate out right away
        console.log('trial finished!');
        console.log(this.currentRound + ' current round', this.rounds + 'total rounds');
        this.stopMusic();
        await this.animation.to(this.container, 1.5, {y: 2000, ease: this.tweenExpo.easeOut});
        await this.determineNextGamePhase();
      }
    },
    async transitionOrderBonus() {
      this.showOrderBonus = true;
      await this.sleep(1000);
      this.showOrderBonus = false;
    },
    // checkCorrectAndInOrder(id) {
    //   const isInArray = idArray.includes(id);
    //   const isFirstItem = isInArray && idArray[0] === id;
    //
    //   // Remove the first item from the array
    //   idArray.shift();
    //
    //   return {
    //     isInArray: isInArray,
    //     isFirstItem: isFirstItem,
    //   };
    // },
    /**
     * The main button click handler, which determines which mode we're in and handles the button press
     * @param button
     * @returns {Promise<void>}
     */
    async evaluateClick(button) {
      if (this.buttonDisabled) return; //avoid button click
      this.buttonDisabled = true;
      let currentButton = this.buttons[button];
      let activeHint = this.hints[this.totalAnswers];

      if (this.hintMode) {
        await this.handleHintMode(button);
      } else {
        await this.handleGamePlayMode(currentButton, button, activeHint);
      }

      this.buttonDisabled = false;
    },
    /**
     * Handle the end of a round and go to the next round
     * @returns {Promise<void>}
     */
    async getRoundScoreAndGoToNext() {
      await this.endRoundScore();
      await this.goToNextRound();
    },
    /**
     * The game play is complete. Show the end of game modal and update the play level
     * @returns {Promise<void>}
     */
    async handleGamePlayComplete() {
      console.log('game play is complete');
      await this.endRoundScore();
      this.increaseTickets(6);
      this.setTicketEffect();
      // all of the answers are correct
      // game play is complete. confetti or whatever
      this.playDisabled = true;
      await this.updatePlayLevel({
        gameId: this.gameId,
        play_id: this.play_id,
        level: Math.max(1, this.level),
        tickets: this.tickets,
        stopTime: new Date().getTime()
      });

      this.movedUpALevel ? this.showMovedUpALevelModal(true) : this.showEndOfGameModal();
    },
    /**
     * Determine the next game phase
     * @returns {Promise<void>}
     */
    async determineNextGamePhase() {
      // save the trial results
      this.showButtons = false;
      await this.setGameLevelAndSaveTrialResults();

      if (!this.gamePlayComplete) {
        console.log('gameplay not complete');
        this.remainingTrials ? await this.goToNextTrial() : await this.getRoundScoreAndGoToNext();
      } else {
        console.log('gameplay is complete');
        await this.handleGamePlayComplete();
      }
    },
    /**
     * Go to the next trial once we've reset necessary variables
     * @returns {Promise<void>}
     */
    async goToNextTrial() {
      this.resetModalMessage();
      this.playDisabled = true;
      this.totalAnswers = 0;
      this.correctThisRound = 0;
      this.incorrectAnswers = false;
      this.hintedButton = false;
      this.startNewRound();
      if (this.currentTrial === 1) {
        this.resetTrialsCorrect();
        await this.getSettings();
      }
      await this.sleep(2400); // so we see stuff before it goes away?
      // this.nextTrial = true
      // await this.initializeGamePlay()

      await this.setupButtons();

      this.setTimer('false');
      await this.animateInAliensAndBoard();

      if (this.currentTrial === 1) this.correctThisRound = 0; // reset this for new rounds
      this.playStart = new Date().getTime(); // start counting the next trial time
      await this.showGameplayHints();

    },
    /**
     * Calculate the score and level for the end of the round
     * @returns {Promise<void>}
     */
    async endRoundScore() {
      // all of the answers are correct
      if (this.allAnswersCorrect) {
        console.log('got all of the answers correct, go up a level');
        this.goUpLevel();
        this.increaseTickets(3);
        this.setTicketEffect();
      }

      // some of the answers are correct
      if (this.someAnswersCorrect) {
        console.log('some answers are correct, stay at same level');
      }

      if (this.noAnswersCorrect) {
        console.log('all of the answers are wrong, go down a level');

        if (this.level > 1) this.goDownLevel();
      }

      await this.updateGameLevel({
        game_id: this.gameId, level: this.level, newTickets: this.tickets
      });
      this.incorrectAnswers = false;
    },
    /**
     * Set trial to pre-0 and go to next round after showing the end of round modal. Show level up if applicable
     * @returns {Promise<void>}
     */
    async goToNextRound() {

      this.showEndOfRoundModal();
    },
    /**
     * Show the end of round modal
     */
    showEndOfRoundModal() {

      if (this.allAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.all;
      } else if (this.someAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.some;
      } else if (this.noAnswersCorrect) {
        this.endRoundText = gamePlayCopy.rounds.none;
      }

      // Set modal message for end of round
      this.setModalMessage({
        title: this.formatText(
            gamePlayCopy.rounds.end,
            {round: this.currentRound},
            false
        ),
        body: this.formatText(this.endRoundText, {correct: this.trialsCorrect}),
        actionButton: {
          action: this.movedUpALevel ? this.showMovedUpALevelModal : this.goToNextTrial,
          text: gamePlayCopy.rounds.playNext
        }
      });

      this.expectedAnswersPerTrial = 0;

      this.clearCorrectAnswers();
      this.incorrectAnswers = false;
    },
    /**
     * Clear all button reactions
     */
    clearAllReactions() {
      this.buttons.forEach(button => button.reaction = null);
    }
  },
  computed: {
    allAnswersCorrect() {
      return this.correctAnswers.length === (this.totalAnswersPerTrial * this.trials);
    },
    someAnswersCorrect() {
      return this.correctAnswers.length < (this.totalAnswersPerTrial * this.trials) && this.correctAnswers.length > 0;
    },
    noAnswersCorrect() {
      return this.correctAnswers.length < 1;
    },
    expectedAnswers() {
      const buttons = this.activeButtons.filter(obj => this.hints.includes(obj.activeColor));
      let lastOccurrences = {};

      buttons.forEach(button => {
        lastOccurrences[button.activeColor] = button;
      });

      return Object.values(lastOccurrences);
    },
    expectedAnswerCount() {
      return this.expectedAnswers.length;
    }
  },
  async mounted() {
    await this.initializeGamePlay(); // start round 1, trial 1
  }
};
