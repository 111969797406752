import {generateAssets} from 'src/shared/responsiveAssetsLoader.js';

const assetsDir = 'games/FirstEncounter/';
const gameAssets = {
  audio: {
    theme: 'theme.mp3',
    gamePlay: 'gameplay.mp3',
    effectCorrect: 'effect-press-correct.mp3',
    effectIncorrect: 'effect-press-incorrect.mp3',
    effectPresentation: 'effect-press-presentation.mp3'
  },
  images: {
    splash: 'splash.webp',
    background: 'background.webp',
    alienShip: 'alien-ship.webp',
    buttonOff: 'btn-black.webp',
    buttonGrey: 'btn-grey.webp',
    buttonRed: 'btn-red.webp',
    buttonGreen: 'btn-green.webp',
    buttonYellow: 'btn-yellow.webp',
    buttonBlue: 'btn-blue.webp',
    buttonOrange: 'btn-orange.webp',
    buttonPurple: 'btn-purple.webp',
    correct: 'button-correct.svg',
    correct_order: 'button-correct-order.svg',
    incorrect: 'button-incorrect.svg',
    orderBonus: 'order-bonus.webp',
    modal: 'modal.webp'
  }
};

export default generateAssets(assetsDir, gameAssets);
